<div id="page-container">
  <div id="content-wrap">
    <app-header></app-header>
<!--    <app-nav></app-nav>-->
    <router-outlet></router-outlet>
  </div>
  <footer id="footer">
    <app-footer></app-footer>
  </footer>
</div>
