<mat-grid-list cols="5" rowHeight="5em">
  <mat-grid-tile colspan="1">
    <span *ngIf="!(isSmallScreen$|async)" style="padding-left: 1em">© Omar Fendri 2021</span>
    <span *ngIf="isSmallScreen$|async" style="padding-left: 1em; font-size: 0.7em">© 2021</span>
  </mat-grid-tile>
  <mat-grid-tile [colspan]="(isSmallScreen$|async)?3:3">
    <a href="https://scully.io/" target="_blank"><img class="clickable" matTooltip="Built with Scully"
                                                      [ngStyle]="{width: (isSmallScreen$|async)?'2.6em':'3.2em'}" style="padding-right: 1em"
                                                      src="../../../../assets/scully.png" alt="Built with Scully"></a>
    <a href="https://github.com/omar-fendri" target="_blank"><img class="clickable" matTooltip="Open-source on github"
                                                                  style="width: 2.2em" [ngStyle]="{width: (isSmallScreen$|async)?'1.5em':'2.2em'}"
                                                                  src="../../../../assets/github.png"></a>
  </mat-grid-tile>
  <mat-grid-tile colspan="1">
    <a href="https://twitter.com/0marFendri" target="_blank"><img class="clickable" matTooltip="Twitter"
                                                                  style="width: 2.2em; padding-right: 0.8em"
                                                                  src="../../../../assets/twitter.png"></a>
<!--    <a href="https://www.facebook.com/fendsoft/" target="_blank"><img class="clickable" matTooltip="Facebook"-->
<!--                                                                      style="width: 1.4em; padding-right: 1em"-->
<!--                                                                      src="../../../../assets/facebook.png"></a>-->
    <a href="https://www.linkedin.com/in/omar-fendri/" target="_blank"><img class="clickable" matTooltip="Linkedin"
                                                                            style="width: 2.4em; padding-right: 1em"
                                                                            src="../../../../assets/linkedin.png"></a>
  </mat-grid-tile>
</mat-grid-list>
